import React from 'react'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from '../common/assets/styles/global'
import Themes from '../common/assets/styles/themes'

import { FaGithub, FaLinkedin } from 'react-icons/fa'

import Logo from '../common/assets/images/logo_vertical.svg'

function App() {
    return (
        <>
            <ThemeProvider theme={Themes.dark}>
                <GlobalStyle />
                <section>
                    <figure>
                        <img src={Logo} alt="Logomarca" />
                    </figure>
                    <span>designer, back and front developer</span>
                    <div className="icons">
                        <a href="https://github.com/willianjr" className="github">
                            <FaGithub />
                        </a>
                        <a href="https://www.linkedin.com/in/willianrochajunior/" className="linkedin">
                            <FaLinkedin />
                        </a>
                    </div>
                </section>
            </ThemeProvider>
        </>
    )
}

export default App
