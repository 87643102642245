import { createGlobalStyle } from 'styled-components'
import themeProps from '../../interfaces/theme'

export const GlobalStyle = createGlobalStyle<{ theme: themeProps }>`
  *{
    font-family:${({ theme: { fonts } }) => fonts.default.family};
    font-size:${({ theme: { fonts } }) => fonts.default.size};
    box-sizing:border-box;

  }
  html{ scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
  }
body{
	  background: ${({ theme: { colors } }) => colors.background.body};
    background-size:cover;
    background-attachment:fixed;
    background-repeat:repeat;
    min-height:100vh;
    overflow:auto;

	}
  input{
    background-color: ${({ theme: { colors } }) => colors.input.default.background};
    border-color: ${({ theme: { colors } }) => colors.input.default.border};
    color: ${({ theme: { colors } }) => colors.input.default.text};
  }
  img{
    @media (max-width:600px) {

        width: 50vw;

    }


  }
  section{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    scroll-snap-align: start;
     flex-direction: column;
     span{
    color:${({ theme: { colors } }) => colors.primary.gold};
    letter-spacing: 0.25rem;
    padding: 1rem 0;
    margin-top:1rem;
    cursor:default;
    @media (max-width:600px) {

       width: 50vw;
       text-align: center;
       letter-spacing: 0rem;
       line-height: 1.5rem;
    }

    }
    .icons{
    display:flex;
    justify-content: center;
    align-items: center;

    a{
      opacity: 0.3;
      transition: all 0.5s ease;
      margin:0.5rem;
      color:#FFFFFF;
      font-size:2rem;
      :hover{opacity: 1;}

}
}
}
`
